.error-page-container{
  padding-top: 5vh;
  text-align: center;
  background-color: $white;
  .error-image{
    z-index:2;
    height: 90vh;
    top: 0;
  }
}
.progress-bar{
  display: flex;
  height: 70vh;
  align-content: center;
  justify-content: center;
  align-items: center;
  svg{
    width:8vh
  }
}
.otp-input-container{
  padding-top:15vh ;
  .otp-input-form{
    margin: auto;
    background-color: $white;
    max-width: 40vw;
    min-width: 300px;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
    border-radius: 15px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  }
}

.role-selection-container{
  padding-top:15vh ;
  .role-selection-form{
    margin: auto;
    background-color: $white;
    max-width: 500px;
    min-width: 300px;
    height: fit-content;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
    border-radius: 15px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
    button{
      width: 200px;
      :hover{
        background-color: $white;
      }
    }
  }
}

.question-form-container{
  padding: 5vh;
  max-width: 700px;
  margin: auto;
  .question-form-file-upload{
    padding: 10px;
    background-color: $off-white;
    width: 100%;
  }
}

.role-selector {
  text-align: center;
  margin: 3rem 1rem;

  .title {
    font-weight: bold;
    margin-bottom: 3rem;
    font-size: 2rem;
  }

  .subtitle {
    color: #666;
    margin-bottom: 2rem;
    font-size: 1rem;
  }

  .roles-container {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;

    .role-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.2rem;
      border-radius: 12px;
      width: 120px;
      height: 140px;
      color: #888;
      border: 2px solid #e0e0e0;
      background-color: #fff;
      transition: all 0.3s ease;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      height: 200px;
      width: 200px;

      .icon {
        font-size: 2.5rem;
        margin-bottom: 0.5rem;
      }

      &.active {
        color: #1976d2;
        border-color: #1976d2;
        background-color: #1976d21e;
        box-shadow: 0px 4px 10px rgba(25, 118, 210, 0.3);

        .icon {
          color: #1976d2;

          img{
            width: 50px;
            height: 50px;
          }
        }
      }

      &:hover {
        border-color: #1976d2;
        color: #ffffff;
        background-color: #1976d2;

        .icon {
          background-color: #ffffff00;

          img{
            background-color: #ffffff00;
          }
        }

        p {
          background-color: #ffffff00;
        }
      }
      

    }
  }

  .selection-message {
    margin-top: 2rem;
    font-size: 1.1rem;
    color: #444;
  }
}


@media (max-width: 760px) {
  .role-selector {
    max-width: 60vw;
    .title {
    }
  
    .subtitle {
    }
  
    .roles-container {
  
      .role-button {
        height: 150px;
        width: 150px;
  
        .icon {

        }
  
        &.active {
  
          .icon {
          }
        }
  
        &:hover {
          .icon {
          }
        }
      }
    }
  
    .selection-message {
    }
  }
}

@media (max-width: 400px) {
  .role-selection-container{
    padding-top: 20px;
    .role-selection-form{
      margin: 10px 10px 100px 10px;
      max-width: 100vw;
      min-width: 300px;
    }
  }
}

