$footer-blue: #15548f;
$footer-blue-dark: #145a8f;
$footer-indigo: #551063;

footer {
  background-color: rgba(255,247,227,0);
  color: $footer-blue;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow-x: hidden;
  transform: translate(0, -100px);

  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;

    .phone-number {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 20px;

      .img{
        margin-right: 10px;
      }
      .text{
        text-decoration: none;
        color: $footer-blue;
      }
    }
    .contact-links {
      display: flex;
      padding-top: 100px;
      justify-content: center;

      ul {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;

        li {
          margin: 10px 20px;
          cursor: pointer;
          display: flex;
          align-items: center;

          .img {
            align-self: center;

            svg {
              width: 40px;
              height: 40px;
              margin: 0;
            }
          }
          .text {
            margin-left: 5px;
          }
        }
      }
    }

    .copyright {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      a{
        margin: 0 5px;
        text-decoration: none;
        color: $footer-blue;
      }
    }

    .css-kw0y8f-MuiSvgIcon-root {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 1em;
      height: 1em;
      display: inline-block;
      fill: $footer-blue;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: 1.5rem;
      color: $footer-blue;
  }
  }

  //Wave animation
  @keyframes move_wave {
    0% {
      background-position-x: 0px;
    }
    40% {
      background-position-x: 450px;
    }
    50% {
      background-position-x: 500px;
    }
    90% {
      background-position-x: 50px;
    }
    100% {
      background-position-x: 0px;
    }
  }

  .wave {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background-repeat: repeat-x;
    position: absolute;
    transform-origin: center bottom;
  }

  .waveTop {
    z-index: -4;
    opacity: 0.5;
    animation: move_wave 15s linear infinite;
    background-image: url("../../assets/footer/wave-bot.png");

    // visibility: hidden;
  }

  .waveMiddle {
    z-index: -3;
    opacity: 0.75;
    animation: move_wave 12s linear infinite;
    background-image: url("../../assets/footer/wave-mid.png");
    // visibility: hidden;
  }

  .waveBottom {
    z-index: -2;
    animation: move_wave 10s linear infinite;
    background-image: url("../../assets/footer/wave-top.png");
    // visibility: hidden;
  }

  @media only screen and (max-width: 650px) {
    .contact-links {
      ul {
        // flex-direction: column;

        li {
          .img {
            img {
            }
          }
          .text {
            visibility: hidden;
            position: absolute;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.footer-wrapper{
  height: 100px;
}
