#mainContent{
  min-height: calc(90vh + 100px - 250px);
  background-color: $off-white;
  padding-bottom: 100px;
}
.footer{
  height: calc(10vh - 100px);
  background-color: $light-grey;
}
.home-container{
  position: relative;
  margin: auto;
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 110%;
    width: 100%;
    min-height: calc(90vh + 100px);
    background-image: url('../../../src/assets/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(2px);
    background-color: rgba(0,0,0, 0.4)
  }
}